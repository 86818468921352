import React, {Component} from 'react'
import {Overlay} from "./Overlay";
import ImageSlider from "./ImageSlider";

class PastryItem extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showOverlay: false,
			showOrderPage: false,
		}
		this.toggleOverlay = this.toggleOverlay.bind(this)
	}

	toggleOverlay() {
		this.setState({showOverlay: !this.state.showOverlay})
	}


	render() {
		return <>
				{this.state.showOverlay && <Overlay closeHandler={this.toggleOverlay}>
					<ImageSlider alt={this.props.product.name} images={[].concat(this.props.product.cover.target, this.props.product.images)} />
				</Overlay>}
				<div className="col-lg-3 col-md-6 col-12 mb-4">
					<div className="product" >
						<div className="productImage" onClick={this.toggleOverlay}>
							{this.props.product.images.length > 0 && <div className="hasImages"><i
								className="far fa-images"></i></div>}
							<img alt={this.props.product.name} src={this.props.product.cover.src}/>
						</div>
						<h5 className="name">
							{this.props.product.name}
						</h5>
						{/*<WhatsAppNotify {...this.props.product} />*/}
						<div className="productDescription col-12">
							<div className="description-wrapper">
								<h5 className="name">
									{this.props.product.name}
								</h5>

								<div className="description">
									<div dangerouslySetInnerHTML={{
										__html: this.props.product.description
									}}/>
								</div>
							</div>
							<div className="pricing-wrapper">
								<div onClick={this.props.toggleShowOrderPage} className="price"><i className="fas fa-shopping-cart large"></i></div>
								{this.props.product.pricing.map(function (item, key) {
									// if (item.onDemand) {
									// 	return <div onClick={this.props.toggleShowOrderPage} key={key} className="price">
									// 		{item.onDemand && <i className="fas fa-shopping-cart large"></i>}
									// 	</div>
									// } else {
										return !item.onDemand &&  <div key={key} className="price">

												{item.units + " "}
												{item.unit === 'person' && <i className='fas fa-user'></i>}
												{item.unit === 'piece' && item.units === '1' && "stuk"}
												{item.unit === 'piece' && item.units !== '1' &&"stuks"}
												{item.unit === 'box' && item.units === '1' && "doos"}
												{item.unit === 'box' && item.units !== '1' && "dozen"}
												{" " + item.price} €
											</div>

									// }

								}, this)}
							</div>
						</div>
					</div>
				</div>
		</>
	}
}

export default PastryItem

function WhatsAppNotify(item) {

	const msg = encodeURIComponent(item.cover.src)
	// const img = <canvas><img src={item.cover.src} /></canvas>
	return 	<div>
		<a target="_blank" rel="noreferrer" href={'https://api.whatsapp.com/send?phone=+32479133921&text=' + msg}><i className="fab fa-2x fa-whatsapp"></i></a>
	</div>
}
